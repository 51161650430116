import axiosInstance from '@/services/axios';
const { nanoid } = require('nanoid');

export default {
  namespaced: true,

  state: {
    raw: [],
    tree: {},
    currentBranch: '',
    due: 0,
    done: 0,
  },
  actions: {
    buildDeckTree ({ state, commit }) {
      const url = '/api/v3/decks';
      return axiosInstance.get(url)
        .then((res) => {
          const raw = res.data;
          commit('setRaw', raw);
          commit('setTree');

          return state.tree;
        });
    },
    getCounts  ({ state, commit }) {
      const url = '/api/v3/stats';
      return axiosInstance.get(url)
        .then((res) => {
          const stats = res.data;
          commit('setDue', stats.due);
          commit('setDone', stats.done);
          return state.done;
        });
    },
  },
  mutations: {
    setRaw (state, raw) { state.raw = raw; },
    setTree (state) {
      const decks = state.raw;

      const masterKey = nanoid(8);
      const keys = new Set([masterKey]);
      const ht = {
        [masterKey]: {
          key: masterKey,
          title: 'master',
          parent: null,
          children: [],
          total: 0,
          due: 0,
        },
      };
      const currentSection = { key: null, title: null };
      const currentChapter = { key: null, title: null };

      decks.forEach((deck) => {
        let key;

        if (deck.section !== currentSection.title) {
          do { key = nanoid(8); } while (keys.has(key));
          ht[key] = {
            key,
            title: deck.section,
            parent: masterKey,
            children: [],
            total: 0,
            due: 0,
          };

          ht[masterKey].children.push(key);
          currentSection.title = deck.section;
          currentSection.key = key;
        }

        if (deck.chapter !== currentChapter.title) {
          do { key = nanoid(8); } while (keys.has(key));
          ht[key] = {
            key,
            title: deck.chapter,
            parent: currentSection.key,
            children: [],
            total: 0,
            due: 0,
          };

          ht[currentSection.key].children.push(key);
          currentChapter.title = deck.chapter;
          currentChapter.key = key;
        }

        key = deck._id;
        ht[key] = {
          key,
          title: deck.title,
          parent: currentChapter.key,
          children: [],
          locked: deck.locked,
          total: deck.flashesTotal,
          due: deck.flashesDue,
        };
        ht[currentChapter.key].children.push(key);

        while (ht[key].parent) {
          key = ht[key].parent;
          ht[key].total += deck.flashesTotal;
          ht[key].due += deck.flashesDue;
        }
      });

      state.tree = ht;
      state.currentBranch = masterKey;

      return state.tree;
    },
    setCurrentBranch (state, key) {
      state.currentBranch = key;
      return state.currentBranch;
    },
    decrementDue ({ state }, { key, amount }) {
      const tree = state.tree;
      if (tree[key].due > 0) {
        tree[key].due -= amount;
        while (tree[key].parent) {
          key = tree[key].parent;
          tree[key].due -= amount;
        }
      }

      return state.tree;
    },
    setDue (state, due) { state.due = due; },
    setDone (state, done) { state.done = done; },
  },
};

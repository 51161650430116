import Vue from 'vue';
import Router from 'vue-router';
import store from '../store';

const PageRegister = () => import('@/pages/PageRegister.vue');
const PageLogin = () => import('@/pages/PageLogin.vue');
const PageDashboard = () => import('@/pages/PageDashboard.vue');
const PageCreateEge = () => import('@/pages/PageCreateEge.vue');
const PageSolve = () => import('@/pages/PageSolve.vue');
const PageEge = () => import('@/pages/PageEge.vue');
const PageDeck = () => import('@/pages/PageDeck.vue');
const PageFlash = () => import('@/pages/PageFlash.vue');
const PageInvite = () => import('@/pages/PageInvite.vue');
const PageValidate = () => import('@/pages/PageValidate.vue');
const PageYes = () => import('@/pages/PageYes.vue');
const Page404 = () => import('@/pages/Page404.vue');

Vue.use(Router);

const routes = [
  {
    path: '/',
    name: 'PageDashboard',
    component: PageDashboard,
    meta: { onlyAuthUser: true },
  },
  {
    path: '/ege/create',
    props: route => ({ ...route.query, ...route.params }),
    name: 'PageCreateEge',
    component: PageCreateEge,
    meta: { onlyAuthUser: true },
  },
  {
    path: '/solve/daily',
    props: route => ({ ...route.query, ...route.params }),
    name: 'PageSolveDaily',
    component: PageSolve,
    meta: { onlyAuthUser: true },
  },
  {
    path: '/solve/:deckIds',
    props: route => ({ ...route.query, ...route.params }),
    name: 'PageSolve',
    component: PageSolve,
    meta: { onlyAuthUser: true },
  },
  {
    path: '/ege',
    props: route => ({ ...route.query, ...route.params }),
    name: 'PageEge',
    component: PageEge,
    meta: { onlyAuthUser: true },
  },
  {
    path: '/deck/:deckId',
    props: true,
    name: 'PageDeck',
    component: PageDeck,
    meta: { onlyAuthUser: true },
  },
  {
    path: '/card/:cardId',
    props: true,
    name: 'PageFlash',
    component: PageFlash,
    meta: { onlyAuthUser: true },
  },
  {
    path: '/login',
    name: 'PageLogin',
    component: PageLogin,
    meta: { onlyGuestUser: true },
  },
  {
    path: '/register',
    name: 'PageRegister',
    component: PageRegister,
    meta: { onlyGuestUser: true },
  },
  {
    path: '/confirm/:hash',
    props: true,
    name: 'PageValidate',
    component: PageValidate,
    meta: { onlyGuestUser: true },
  },
  {
    path: '/invite/:invite',
    props: true,
    name: 'PageInvite',
    component: PageInvite,
    meta: { onlyAuthUser: true },
  },
  {
    path: '/exam/yes',
    name: 'PageYes',
    component: PageYes,
  },
  {
    path: '/404',
    alias: '*',
    name: 'Page404',
    component: Page404,
    meta: { onlyAuthUser: true },
  },
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});


router.beforeEach((to, from, next) => {
  if (from.name) localStorage.setItem('prevPath', from.path);
  else localStorage.setItem('prevPath', '');

  store.dispatch('auth/getAuthUser')
    .then(() => {
      const isAuthenticated = store.getters['auth/isAuthenticated'];
      if (to.meta.onlyAuthUser) {
        if (isAuthenticated) {
          // if logged in with invite - go finish invitation
          if (to.name !== 'PageInvite') {
            const invite = localStorage.getItem('invite');
            if (invite) next({ name: 'PageInvite', params: { invite } })
            else next();
          }
          else next();
        } else {
          // if going with invite, but not logged in - save invite and login first
          if (Object.prototype.hasOwnProperty.call(to.params, 'invite'))
            localStorage.setItem('invite', to.params.invite);
          next({ name: 'PageLogin' });
        }
      } else if (to.meta.onlyGuestUser) {
        if (isAuthenticated) next({ name: 'PageDashboard' });
        else next();
      } else next();
    });
});


export default router;

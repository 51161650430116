import axiosInstance from '@/services/axios';
import Vue from 'vue';

function shuffleOptions (task) {
  const isUpper = (str) => str.toUpperCase() === str;
  const decount = (str) => str.replace(/^\d\) |^[А-ЯA-Z]\) /g, '');
  const shuffle = (o) => {
    for (var j, x, i = o.length; i; j = parseInt(Math.random() * i), x = o[--i], o[i] = o[j], o[j] = x);
    return o;
  };
  const processAns = (ans) => ans.toLowerCase().split(' или ');

  function recount (strArr, lettered = false) {
    const alphabet = 'АБВГДЕЖЗИК'.split('');
    if (lettered) return strArr.map((s,i) => `${alphabet[i]}) ${s}`);
    return strArr.map((s,i) => `${i + 1}) ${s}`);
  }

  if (task.kind === 'term')
    return [task.task, processAns(task.ans)];

  if (/NO_SHUFFLE\n/g.test(task.task))
    return [task.task.replace(/NO_SHUFFLE\n/g, ''), processAns(task.ans)];

  const tParts = task.task.split('\n\n');
  const aChars = task.ans.split('');
  const tLines = tParts[1].split('\n').map(tl => decount(tl));

  let title;
  if (isUpper(tLines[0]) && tLines[0].length > 2 && tLines[0].replace(/[A-Za-z0-9]/g, '').length > 2)
    title = tLines.shift();

  if (tLines.length < 4)
    return [task.task, processAns(task.ans)];

  if (task.kind === 'pick' && tLines.length < 7) {
    const mergedArray = tLines.map((tl, i) => ({ line: tl, correct: aChars.includes((i + 1).toString()) }));
    shuffle(mergedArray);
    const { lines, ans } = mergedArray.reduce((a,c,i) => {
      a.lines.push(c.line);
      if (c.correct) a.ans.push((i + 1).toString());
      return a;
    }, { lines: [], ans: [] });

    tParts[1] = [title, ...recount(lines)].filter(tp => !!tp).join('\n');

    return [tParts.join('\n\n'), processAns(ans.join(''))];
  }

  if (task.kind === 'match') {
    const mergedArray = tLines.map((tl, i) => ({ line: tl, option: aChars[i] }));
    shuffle(mergedArray);
    const { lines, ans } = mergedArray.reduce((a,c) => {
      a.lines.push(c.line);
      a.ans.push(c.option);
      return a;
    }, { lines: [], ans: [] });

    tParts[1] = [title, ...recount(lines, true)].filter(tp => !!tp).join('\n');

    return [tParts.join('\n\n'), processAns(ans.join(''))];
  }

  if (task.kind === 'sequence' || tLines.length > 6) {
    const mergedArray = tLines.map((tl, i) => ({ line: tl, position: aChars.indexOf((i + 1).toString()) }));
    shuffle(mergedArray);
    const { lines, ans } = mergedArray.reduce((a,c,i) => {
      a.lines.push(c.line);
      if (c.position >= 0) a.ans[c.position] = (i + 1).toString();
      return a;
    }, { lines: [], ans: Array(mergedArray.length).fill(undefined) });

    tParts[1] = [title, ...recount(lines)].filter(tp => !!tp).join('\n');

    return [tParts.join('\n\n'), processAns(ans.filter(a => !!a).join(''))];
  }

  return [task.task, processAns(task.ans)];
}

export default {
  namespaced: true,

  state: {
    task: {},
    tasks: [],
    tasksTotal: 0,
    taskNum: 0,
  },
  actions: {
    getTasks ({ state, commit }, { mode, locations, size }) {
      const url = `/api/v3/ege/tasks`;
      return axiosInstance.post(url, {  mode, locations, size })
        .then((res) => {
          const tasks = res.data;
          commit('setTasks', tasks);
          commit('setTasksTotal');

          const task = state.tasks.shift();
          if (!!task) {
            [task.task, task.ans] = shuffleOptions(task);
            commit('setTask', task);

            commit('incrTaskNum');
          }

          return state.tasks;
        });
    },
    getTasksByTids ({ state, commit }, { tids }) {
      const url = `/api/v3/ege/tasks/concrete`;
      return axiosInstance.get(url, { params: { tids } })
        .then((res) => {
          const tasks = res.data;
          commit('setTasks', tasks);
          commit('setTasksTotal');

          const task = state.tasks.shift();
          if (!!task) {
            [task.task, task.ans] = shuffleOptions(task);
            commit('setTask', task);

            commit('incrTaskNum');
          }

          return state.tasks;
        });
    },
    nextTask ({ state, commit }) {
      const task = state.tasks.shift();
      if (!!task) {
        [task.task, task.ans] = shuffleOptions(task);
        commit('setTask', task);

        commit('incrTaskNum');
      } else commit('setTask', null);
    },
    sendStat ({ state }, stat) {
      const url = `/api/v3/ege/stat`;
      return axiosInstance.post(url, stat)
        .then(() => true);
    },
  },
  mutations: {
    initStore (state) {
      Vue.set(state, 'task', {});
      Vue.set(state, 'tasks', []);
      Vue.set(state, 'tasksTotal', 0);
      Vue.set(state, 'taskNum', 0);
    },
    setTasks (state, tasks) { return state.tasks = tasks; },
    setTask (state, task) { return state.task = task; },
    setTasksTotal (state) { return state.tasksTotal = state.tasks.length; },
    incrTaskNum (state) { return state.taskNum++; },
    toggleFlag (state) { return state.task.flagged = !state.task.flagged },
  },
};

import axios from 'axios';

const axiosInstance = axios.create({ timeout: 20 * 1000 });

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('user-jwt') || '';

  if (token) config.headers.authorization = `Bearer ${token}`;

  return config;
}, err => Promise.reject(err));

export default axiosInstance;

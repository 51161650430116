import axios from 'axios';
import jwt from 'jsonwebtoken';
import axiosInstance from '@/services/axios';

function checkTokenValidity (token) {
  if (token) {
    const decodedToken = jwt.decode(token);

    return decodedToken && (decodedToken.exp * 1000) > new Date().getTime();
  }

  return false;
}

export default {
  namespaced: true,
  state: {
    user: null,
  },
  getters: {
    getUser (state) { return state.user; },
    isAuthenticated (state) { return !!state.user; },
  },
  actions: {
    registerUser(context, userData) {
      return axios.post('/api/v1/users/register', userData);
    },
    loginWithEmailAndPassword(context, userData) {
      return axios.post('/api/v1/users/login', userData)
        .then((res) => {
          const user = res.data;
          localStorage.setItem('user-jwt', user.token);
          context.commit('setUser', user);
        });
    },
    getAuthUser({ state, commit }) {
      const user = state.user;
      const token = localStorage.getItem('user-jwt');
      const isTokenValid = checkTokenValidity(token);

      if (user && isTokenValid) { return Promise.resolve(user); }

      const config = { headers: { 'Cache-Control': 'no-cache' } };

      return axiosInstance.get('/api/v1/users/me', config)
        .then((res) => {
          const user = res.data;
          localStorage.setItem('user-jwt', user.token);
          commit('setUser', user);
          return user;
        }).catch((err) => {
          commit('setUser', null);
          return err;
        });
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        localStorage.removeItem('user-jwt');
        commit('setUser', null);
        resolve(true);
      });
    },
  },
  mutations: {
    setUser (state, user) {
      state.user = user;
      return user;
    },
  },
};

import Vue from 'vue';
import vuelidate from 'vuelidate';
import Vue2TouchEvents from 'vue2-touch-events';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faPen } from '@fortawesome/free-solid-svg-icons/faPen';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import { faImages } from '@fortawesome/free-solid-svg-icons/faImages';
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { faExclamation } from '@fortawesome/free-solid-svg-icons/faExclamation';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle';
import { faRandom } from '@fortawesome/free-solid-svg-icons/faRandom';
import { faBomb } from '@fortawesome/free-solid-svg-icons/faBomb';
import { faFlag } from '@fortawesome/free-solid-svg-icons/faFlag';
import { faBackspace } from '@fortawesome/free-solid-svg-icons/faBackspace';
import { faTable } from '@fortawesome/free-solid-svg-icons/faTable';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';
import './assets/tailwind.css';


library.add(faArrowLeft, faSignOutAlt, faTimes, faPlus, faCheck, faEye,
  faEdit, faPen, faTrashAlt, faImages, faLock, faExclamationTriangle,
  faExclamation, faQuestionCircle, faRandom, faBomb, faFlag, faBackspace,
  faTable);

Vue.component('font-awesome-icon', FontAwesomeIcon);

const innerHeight = require('ios-inner-height');
Object.defineProperty(Vue.prototype, '$innerHeight', { value: innerHeight });

Vue.use(vuelidate);
Vue.use(Vue2TouchEvents, { touchHoldTolerance: 200 });

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');

<template>
  <router-view :key="$route.path"></router-view>
</template>

<script>
export default {
  name: 'app',
};
</script>

<style lang="scss">

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
  "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

button {
  touch-action: manipulation;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

//.wrapper {
//  display: block;
//  margin: auto;
//  overflow-y: auto;
//  -webkit-overflow-scrolling: touch;
//
//  @media (max-width: 639px) {
//    width: 100%;
//  }
//
//  @media (min-width: 640px) {
//    width: 75%;
//  }
//
//  @media (min-width: 1024px) {
//    width: 50%;
//  }
//}

</style>

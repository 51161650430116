import axiosInstance from '@/services/axios';

export default {
  namespaced: true,

  state: {
    deck: {},
    card: {},
  },
  actions: {
    fetchDeck ({ state, commit }, deckId) {
      const url = `/api/v3/decks/${deckId}`;
      return axiosInstance.get(url)
        .then((res) => {
          const deck = res.data;
          commit('setDeck', deck);
          return state.deck;
        });
    },
    fetchCard ({ state, commit }, cardId) {
      const url = `/api/v3/decks/card/${cardId}`;
      return axiosInstance.get(url)
        .then((res) => {
          const card = res.data;
          commit('setCard', card);
          return state.card;
        });
    }
  },
  mutations: {
    setDeck (state, deck) { return state.deck = deck; },
    setCard (state, card) { return state.card = card; },
  },
};

import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth';
import stats from './modules/stats';
import deck from './modules/deck';
import flashes from './modules/flashes';
import egetasks from './modules/egetasks';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    stats,
    deck,
    flashes,
    egetasks,
  },
  mutations: {
    setItems (state, { resource, items }) {
      state[resource].items = items;
    },
    setItem (state, { resource, item }) {
      state[resource].item = item;
    },
  },
});

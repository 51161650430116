import Vue from 'vue';
import axiosInstance from '@/services/axios';
const { nanoid } = require('nanoid');

const testing = {
  card: {
    chances: [0.05, 0.15, 0.2, 0.15, 0.1, 0.05],
    getPenaltyChance: (meanDuration) => {
      if (meanDuration < 500) return 0.75;
      if (meanDuration < 1000) return 0.6;
      if (meanDuration < 1500) return 0.4;
      if (meanDuration < 2000) return 0.2;
      return 0;
    },
  },
  field: {
    fieldCountChances: [0.5, 0.8, 1],
    getTotalCount: (card) => {
      return card.answer.reduce((a,block) => {
        if (block.blockType === 'text')
          a += (block.content.match(/(\*\*.+?\*\*|__.+?__)/g) || []).length;
        return a;
      }, 0);
    },
  },
};
testing.card.getBaseChance = (iter) => {
  const idx = Math.min(iter, testing.card.chances.length - 1);
  console.log(`Base chance: ${testing.card.chances[idx]}`);
  return testing.card.chances[idx];
};
testing.card.getChance = (iter, meanDuration) => {
  console.log(`Penalty chance: ${testing.card.getPenaltyChance(meanDuration)}`);
  return Math.min(testing.card.getBaseChance(iter) + testing.card.getPenaltyChance(meanDuration), 1);
};
testing.field.getCountToTest = (maxCountToTest) => {
  const r = Math.random();
  let result = 1;
  for (let i = 0; i < testing.field.fieldCountChances.length; i++) {
    if (r < testing.field.fieldCountChances[i]) return Math.min(result, maxCountToTest);
    result++;
  }
};
testing.field.getNumsToTest = (card) => {
  const total = testing.field.getTotalCount(card);
  const toTest = testing.field.getCountToTest(total);
  return [...Array(total)]
    .map(_ => Math.floor(Math.random() * total))
    .slice(0, toTest);
};


export default {
  namespaced: true,

  state: {
    isDueMode: false,
    isTesting: false,
    testFailed: false,
    currentStep: 'recalling',
    flashes: [],
    flashesTotal: 0,
    flash: {},
    flashNum: 0,
    timer: {
      lastDuration: 0,
      lastTimestamp: new Date(),
      testing: {
        history: [2000],
      },
      usual: {
        history: [2000],
      },
    },
  },
  actions: {
    getFlashes({ state, dispatch, commit }, { deckIds, onlyDue }) {
      let url;
      if (!deckIds || deckIds.length === 0) url = '/api/v3/flashes/dose';
      else url = `/api/v3/flashes?deckIds=${deckIds}&onlyDue=${onlyDue}`;

      return axiosInstance.get(url)
        .then((res) => {
          const flashes = res.data.sort((a, b) => b.iteration - a.iteration).reverse();
          commit('setDueMode', onlyDue);
          commit('setCurrentStep', 'recalling');
          commit('setFailedStatus', false);
          commit('setFlashes', flashes);
          commit('setFlashesTotal');

          if (flashes.length > 0) {
            commit('setFlash');
            commit('incrFlashNum');
            commit('setTestingMode');
            commit('prepareTestingFields');
          }
        });
    },
    rotateCard ({ state, commit }) {
      if (state.isTesting) return commit('setCurrentStep', 'testing');
      return commit('setCurrentStep', 'summarizing');
    },
    checkCard ({ commit }) {
      return commit('setCurrentStep', 'summarizing');
    },
    nextCard({ state, dispatch, commit }, isRecalled) {
      return new Promise((resolve) => {
        commit('updateTimer');
        dispatch('sendStats', isRecalled);
        commit('setCurrentStep', 'recalling');
        commit('resetFailedStatus');
        if (isRecalled) {
          commit('removeFlash');
          commit('incrFlashNum');
        } else {
          state.flashes[0].iteration = 0;
          commit('rotateFlashes');
        }
        commit('setFlash');
        commit('setTestingMode');
        commit('prepareTestingFields');
        resolve(true);
      });
    },
    sendStats({ state, commit }, isRecalled) {
      const stats = {
        deck: state.flash.deckInfo._id,
        flash: state.flash._id,
        correctly: isRecalled,
        multiplier: 2,
        iterationBeforeSolving: state.flash.iteration,
        inSolvingMode: state.isDueMode,
        timeSpent: state.timer.lastDuration,
      };

      return axiosInstance.post('/api/v3/stats/flash', stats)
        .then(res => res.data)
        .catch();
    },
  },
  mutations: {
    initStore (state) {
      const timer = {
        lastDuration: 0,
        lastTimestamp: new Date(),
        testing: { history: [2000] },
        usual: { history: [2000] },
      };
      Vue.set(state, 'isDueMode', false);
      Vue.set(state, 'isTesting', false);
      Vue.set(state, 'testFailed', false);
      Vue.set(state, 'currentStep', 'recalling');
      Vue.set(state, 'flashes', []);
      Vue.set(state, 'flashesTotal', 0);
      Vue.set(state, 'flash', {});
      Vue.set(state, 'flashNum', 0);
      Vue.set(state, 'timer', timer);

      return true;
    },
    setDueMode (state, onlyDue) { return state.isDueMode = onlyDue; },
    setCurrentStep (state, step) { return state.currentStep = step; },
    setFlashes (state, flashes) { return state.flashes = flashes; },
    setFlashesTotal (state) { return state.flashesTotal = state.flashes.length; },
    setFlash (state) {
      if (state.flashes.length > 0) {
        const flash = { ...state.flashes[0] };
        if (flash?.deckInfo?.reversible && Math.random() < 0.5) {
          flash.question = state.flashes[0].answer;
          flash.answer = state.flashes[0].question;
        }

        let key;
        const keys = new Set();
        for (const side of ['question', 'answer']) {
          for (const block of flash[side]) {
            do { key = nanoid(8); } while (keys.has(key));
            block.key = key;
          }
        }

        return state.flash = flash;
      }

      state.currentStep = 'finished';
      return state.flash = undefined;
    },
    incrFlashNum (state) { if (state.flashes.length > 0) return state.flashNum++; },
    rotateFlashes (state) { return state.flashes.push(state.flashes.shift()); },
    removeFlash (state) { return state.flashes.shift(); },
    setTestingMode (state) {
      const { flash, timer } = state;
      const meanUsualDuration = timer.usual.history.reduce((a, b) => a + b) / timer.usual.history.length;
      const testChance = testing.card.getChance(flash.iteration, meanUsualDuration);
      console.log(`Total chance: ${testChance}`);
      const fieldCountTotal = testing.field.getTotalCount(flash);
      if (fieldCountTotal > 0 && Math.random() < testChance)
        return state.isTesting = true;
      return state.isTesting = false;
    },
    prepareTestingFields (state) {
      if (!state.isTesting) return true;

      const { flash } = state;
      const fieldNumsToTest = testing.field.getNumsToTest(flash);
      let fieldCounter = 0;
      let spareAsterisks = false;

      for (let i = 0; i < flash.answer.length; i++) {
        if (flash.answer[i].blockType !== 'text') continue;
        const splitted = flash.answer[i].content.split(/(\*\*.+?\*\*|__.+?__)/);
        for (let h = 0; h < splitted.length; h++) {
          if (/__/.test(splitted[h])) {
            if (!fieldNumsToTest.includes(fieldCounter))
              splitted[h] = splitted[h].replaceAll('__', '');
            fieldCounter++;
          } else if (/\*\*/.test(splitted[h])) {
            if (fieldNumsToTest.includes(fieldCounter))
              spareAsterisks = true;
            fieldCounter++;
          }
        }
        flash.answer[i].content = splitted.join('');
      }

      if (!spareAsterisks) {
        for (let i = 0; i < flash.answer.length; i++) {
          if (flash.answer[i].blockType !== 'text') continue;
          flash.answer[i].content = flash.answer[i].content.replaceAll('**', '');
        }
      }
    },
    resetFailedStatus (state) { return state.testFailed = false; },
    setFailedStatus (state, status) {
      if (status) state.testFailed = status;
      return state.testFailed;
    },
    updateTimer (state) {
      const { timer, isTesting } = state;
      const now = new Date();
      const lastDuration = now - timer.lastTimestamp;

      if (isTesting) timer.testing.history.push(lastDuration);
      else timer.usual.history.push(lastDuration);

      if (timer.testing.history.length > 10) timer.testing.history.shift();
      if (timer.usual.history.length > 10) timer.usual.history.shift();

      timer.lastDuration = lastDuration;
      timer.lastTimestamp = now;

      return state.timer;
    }
  },
}
